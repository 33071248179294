
























import AssignRulesListItem from './AssignRulesListItem/AssignRulesListItem.vue';
import {
  Component, Vue, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ScenariosAction,
  ScenariosActionType,
  ScenariosState
} from '@/store/modules/scenarios/types';

const scenarios = namespace('scenarios');

@Component({
  components: {
    AssignRulesListItem
  }
})
export default class AssignRulesList extends Vue {
  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid'];

  @scenarios.State
  scenarioElements!: ScenariosState['scenarioElements'];

  @scenarios.Action(ScenariosAction.FetchScenarioElementsByScenario)
  fetchScenarioElementsByScenario!: ScenariosActionType['FETCH_SCENARIO_ELEMENTS_BY_SCENARIO'];

  @Watch('selectedScenarioUuid')
  onSelectedScenarioUuidChange(uuid) {
    if (uuid) {
      this.fetchScenarioElementsByScenario({ uuid });
    }
  }

  mounted() {
    if (this.selectedScenarioUuid) {
      this.fetchScenarioElementsByScenario({ uuid: this.selectedScenarioUuid as string });
    }
  }
}
