



































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DevicesAction, DevicesActionType } from '@/store/modules/devices/types';
import { AppAction, AppActionType, AppState } from '@/store/modules/app/types';
import OptimizationCardTabbedList from './OptimizationCardTabbedList/OptimizationCardTabbedList.vue';
import OptimizationCardRightSidePanel from './OptimizationCardRightSidePanel/OptimizationCardRightSidePanel.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const app = namespace('app');
const devices = namespace('devices');
const admin = namespace('admin');

export enum OptimizationCardTab {
  Circuits,
  Groups
}

@Component({
  components: {
    OptimizationCardTabbedList,
    OptimizationCardRightSidePanel,
  },
})
/**
 * @group Optimization Card
 * Optimization tab content
 */
export default class OptimizationCard extends Vue {
  selectedOptimizationTab = OptimizationCardTab.Groups

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB']

  @app.Action(AppAction.SetMapSelectionEnabled)
  setMapSelectionEnabled!: AppActionType['SET_MAP_SELECTION_ENABLED']

  @devices.Action(DevicesAction.SetInsidePolygonMarkers)
  setInsidePolygonMarkers!: DevicesActionType['SET_INSIDE_POLYGON_MARKERS'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get optimizationTabs() {
    return [
      { name: this.$tc('main.group', 2), value: OptimizationCardTab.Groups },
      { name: this.$tc('main.circuit', 2), value: OptimizationCardTab.Circuits },
    ];
  }

  get assignListElements() {
    return this.selectedOptimizationTab === OptimizationCardTab.Circuits ? 'lamps' : 'circuits';
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.delete;
  }

  selectOnMap() {
    this.setInsidePolygonMarkers([]);
    this.setMapSelectionEnabled(true);
    this.setSelectedTab(null);
  }
}
