<template>
  <div :id="`previewChartContainer-${scenarioElementId}`" class="svg-container pt-2" flat tile>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ScenarioRulePreviewChart',
  props: {
    graph: {
      type: Object,
      required: true,
      validator: (g) => g.changes.length === 96 && g.changes.every((e) => typeof e === 'number' && e >= 0 && e <= 100)
    },
    optimizedGraph: {
      type: Object,
      required: true,
      validator: (g) => g.changes.length === 96 && g.changes.every((e) => typeof e === 'number' && e >= 0 && e <= 100)
    },
    scenarioElementId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      width: 500,
      height: 100,
      margin: {
        top: 5,
        right: 0,
        bottom: 20,
        left: 35,
      },
      svg: null,
    };
  },
  computed: {
    chartData() {
      // return this.graph.changes.map((d, i) => ({ time: i * 15, duty: d }));
      return [this.graph.changes.map((d, i) => ({ time: i * 15, duty: d })), this.optimizedGraph.changes.map((d, i) => ({ time: i * 15, duty: d }))];
    },
    xRange() {
      return [this.margin.left, this.width - this.margin.right];
    },
    xScale() {
      const scale = d3
        .scaleBand()
        // .domain(this.chartData.map((d) => d.time))
        .domain(this.chartData[0].map((d) => d.time))
        .range(this.xRange);
      return scale;
    },
    yScale() {
      return d3
        .scaleLinear()
        .domain([0, 100])
        .range([this.height - this.margin.bottom, this.margin.top]);
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  methods: {
    formatDate(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes - (hours * 60);
      let minutesDisplay = `${remainingMinutes}`;
      minutesDisplay = minutesDisplay.length === 1 ? `0${minutesDisplay}` : minutesDisplay;
      let hoursDisplay = `${hours}`;
      hoursDisplay = hoursDisplay.length === 1 ? `0${hoursDisplay}` : hoursDisplay;
      return `${hoursDisplay}:${minutesDisplay}`;
    },
    xAxis(g) {
      const axis = g
        .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickFormat((d) => this.formatDate(d))
            .tickValues(this.xScale.domain().filter((d, i) => !(i % 8)))
        );
      axis.select('.domain').remove();
      axis
        .selectAll('g.tick line')
        // .attr('transform', `translate(${-this.xScale.bandwidth() / 2}, 0)`);
        .remove();
      axis
        .selectAll('g.tick text')
        .attr('fill', 'grey')
        .attr('transform', `translate(${-this.xScale.bandwidth() / 2}, 0)`);
      return axis;
    },
    yAxis(g) {
      return (
        g
          .attr('transform', `translate(${this.margin.left}, 0)`)
          .call(d3.axisLeft(this.yScale)
            .tickSize(0)
            .tickFormat((d) => {
              if (d === 100) {
                return '100%';
              } if (d === 0) {
                return '0%';
              }
              return '';
            })
            .tickPadding(8))
          .call((g1) => g1.selectAll('.tick:not(:first-of-type) line')
            .attr('stroke-opacity', 0.5)
            .attr('stroke-dasharray', '2,2'))
          .call((g1) => g1.select('.domain').remove())
          .call((g2) => g2
            .append('text')
            .attr('x', -this.margin.left)
            .attr('y', 10))
          .call((g3) => g3.selectAll('.tick line').clone()
            .attr('x2', this.width - this.margin.left - this.margin.right)
            .attr('stroke-opacity', 0.07))
      );
    },
    updateChart() {
      const bars = this.svg.selectAll('.bar')
        .data(this.chartData[0], (d) => d.time);
      const optimizedBars = this.svg.selectAll('.bar2')
        .data(this.chartData[1], (d) => d.time);

      // SWITCH BELOW Bars order (optimizedBars, bars) to determine which of them is in the front and which is at the back
      optimizedBars.enter()
        .append('rect')
        .attr('class', 'bar2')
        .merge(optimizedBars)
        .attr('fill', 'rgba(14, 209, 169, 0.76)')
        .attr('stroke', 'rgba(14, 209, 169, 0.35)')
        .attr('width', this.xScale.bandwidth())
        .attr('height', (d) => this.yScale(0) - this.yScale(d.duty))
        .attr('x', (d) => this.xScale(d.time))
        .attr('y', (d) => this.yScale(d.duty));
      bars.enter()
        .append('rect')
        .attr('class', 'bar')
        .merge(bars)
        .attr('fill', 'rgba(255, 126, 5, 0.76)')
        .attr('stroke', 'rgba(255, 126, 5, 0.35)')
        .attr('width', this.xScale.bandwidth())
        .attr('height', (d) => this.yScale(0) - this.yScale(d.duty))
        .attr('x', (d) => this.xScale(d.time))
        .attr('y', (d) => this.yScale(d.duty));

      // bars.exit().remove();
    },
  },
  mounted() {
    this.svg = d3
      .select(`#previewChartContainer-${this.scenarioElementId}`)
      .append('svg')
      .attr('viewBox', [0, 0, this.width, this.height])
      .style('background-color', 'none');

    this.svg.append('g').attr('class', 'xAxis').call(this.xAxis);
    this.svg.append('g').attr('class', 'yAxis').call(this.yAxis);

    this.updateChart();
  },
};
</script>

<style lang="scss">
.svg-container {
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
