<template>
  <v-dialog
    max-width="290"
    content-class="optimization-dialog"
    v-model="showDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#f0f0f0"
        small
        icon
        fab
        class="l-rule-card__approve-button"
        v-bind="attrs"
        v-on="on"
        :title="$t(`tooltips.optimize`)"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
    <div>
      <div class="optimization-dialog__header">
        <div class="optimization-dialog__icon-div">
          <v-icon size="85" color="#b90000">mdi-alert-outline</v-icon>
        </div>
      </div>
      <v-card class="optimization-dialog__body" flat>
        <v-card-title class="text-h5 optimization-dialog__title">
          {{ $t(`messages.permittingManualControl`) }}
        </v-card-title>
        <v-card-text
          color="rgba(0, 0, 0, 0.6)"
          class="optimization-dialog__text text-center"
          center
        >
          {{ $t(`messages.activeGraphsCanBeOverridden`) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#f0f0f0"
            text
            @click="approveOptimization"
            rounded
            class="optimization-dialog__button"
            large
          >
            {{ $t(`actions.next`) }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      showDialog: false,
    };
  },
  emits: ['approveOptimization'],
  methods: {
    approveOptimization() {
      this.showDialog = false;
      this.$emit('approveOptimization');
    },
  },
};
</script>

<style lang="scss">
  .optimization-dialog {
    box-shadow: none !important;
    &__header {
      display: flex;
      justify-content: center;
      height: 45px;
    }
    &__body {
      border-radius: 10px;
      box-shadow: none !important;
    }
    &__icon-div {
      margin-bottom: -40px;
      max-width: 290px;
      display: flex;
      justify-content: center;
      z-index: 1 !important;
    }
    &__title {
      color: #b90000;
      font-weight: 900 !important;
      padding-top: 45px !important;
      text-align: center;
      justify-content: center;
    }
    &__text {
      font-size: 1rem;
    }
    &__button {
      background-color: rgb(14, 209, 169);
      font-size: 13.5px;
      line-height: 1.3;
      width: 40%;
      margin-bottom: 5px !important;
    }
  }
</style>
