












import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AssignRulesList from './AssignRulesList/AssignRulesList.vue';
import { ScenariosState } from '@/store/modules/scenarios/types';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const scenarios = namespace('scenarios');
const admin = namespace('admin');

@Component({
  components: {
    AssignRulesList,
  }
})
export default class OptimizationCardRightSidePanel extends Vue {
  showModal = false

  @scenarios.State
  selectedScenarioElementID!: ScenariosState['selectedScenarioElementEditID']

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID']

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @Watch('selectedScenarioElementID')
  onSelectedScenarioElementIDChange(scenarioElementId: number) {
    if (scenarioElementId) this.showModal = true;
  }

  get isScenarioSelected() {
    return !!this.selectedScenarioID;
  }

  get writePermission() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.write;
  }

  get deletePermission() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.delete;
  }

  addScenarioElement() {
    this.showModal = true;
  }
}
