











import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { OptimizationCardTab } from '../OptimizationCard.vue';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const admin = namespace('admin');

const groupsTab: any = () => ({
  component: import('./EditableGroupsList/EditableGroupsList.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const circuitsTab: any = () => ({
  component: import('./EditableCircuitsList/EditableCircuitsList.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    groupsTab,
    circuitsTab
  }
})
export default class OptimizationCardTabbedList extends Vue {
  @Prop(Number) readonly selectedTab!: OptimizationCardTab;

  @Prop(Array) readonly tabs!: Array<{ name: string, value: OptimizationCardTab }>

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get currentTab() {
    return this.selectedTab === OptimizationCardTab.Groups ? 'groupsTab' : 'circuitsTab';
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.delete;
  }
}
