





















































































import ScenarioRulePreviewChart from './Chart/ScenarioRulePreviewChart.vue';
import OptimizationDialog from './OptimizationDialog/OptimizationDialog.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { rrulestr } from 'rrule';
import { Models } from '@mtap-smartcity/api';
import { ScenariosAction, ScenariosActionType } from '@/store/modules/scenarios/types';
import { GraphsGetter, GraphsGetterType } from '@/store/modules/graphs/types';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const scenarios = namespace('scenarios');
const graphs = namespace('graphs');
const admin = namespace('admin');

@Component({
  components: {
    ScenarioRulePreviewChart,
    OptimizationDialog,
  }
})
export default class AssignRulesListItem extends Vue {
  @Prop(Object) readonly scenarioElement!: Models.ScenarioElements.Model

  @Prop(Object) readonly optimizationBenefits!: { energy: number, lampObsolescence: number } | undefined

  @scenarios.Action(ScenariosAction.DeleteScenarioElement)
  deleteScenarioElement!: ScenariosActionType['DELETE_SCENARIO_ELEMENT']

  @scenarios.Action(ScenariosAction.SetSelectedScenarioElementID)
  setSelectedScenarioElementID!: ScenariosActionType['SET_SELECTED_SCENARIO_ELEMENT_ID']

  @graphs.Getter(GraphsGetter.GetGraph)
  getGraph!: GraphsGetterType['GET_GRAPH'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  showOptimization = false;

  dialog = false;

  // Temporary hardcoded data for optimized graph data
  optimizedGraph = {
    changes: [
      70,
      55,
      55,
      55,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      70,
      55,
      55,
      55,
      55,
      40,
      40,
      40,
      40,
      40,
      40,
      25,
      25,
      25,
      25,
      0,
      0,
      14,
      14,
      14,
      14,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      14,
      14,
      14,
      14,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      35,
      35,
      35,
      35,
      40,
      40,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
    ],
    createdAt: new Date(),
    enabled: true,
    id: 123,
    scenarioElements: [
      {
        astronomical_control: false,
        createdAt: new Date(),
        enabled: true,
        graph_id: '43962a67-2fb1-4b8b-8dba-610d17769429',
        id: 123,
        rrule: 'DTSTART:20211004T204803Z\nRRULE:FREQ=DAILY;INTERVAL=1',
        scenario_id: 'c939f4af-9404-4fa0-94c4-374c0fc6cec7',
        uuid: '01e57837-1f02-49df-bc62-b011d00f7dea',
        version: 2,
      },
    ],
    uuid: '43962a67-2fb1-4b8b-8dba-610d17769429',
    version: 2,
  }

  // Temporary optimization benefits object - possibly to be calculated on the backend
  get optimizationBenefitsComputed() {
    let sum1: number;
    let sum2: number;
    if (this.graph && this.graph.changes.length) {
      sum1 = this.graph.changes.reduce((partialSum, a) => partialSum + a, 0);
      sum2 = this.optimizedGraph.changes.reduce((partialSum, a) => partialSum + a, 0);
    } else {
      sum1 = 0;
      sum2 = 0;
    }

    return { energy: Math.floor((sum2 - sum1) / 100), lampObsolescence: Math.floor((((sum2 - sum1) * 0.7)) / 100) };
  }

  get recurrence() {
    return rrulestr(this.scenarioElement.rrule).options;
  }

  get graph(): Models.ScenarioGraphs.Model | undefined {
    // ORIGINAL graph() computed property to be found in Planning
    if (!this.scenarioElement.graph_id) return { changes: [] };
    return this.getGraph(this.scenarioElement.graph_id);
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.delete;
  }

  approveOptimization() {
    // FUNCTION WILL CALL ENDPOINT THAT UPDATES THE RULE
    this.updateChart();
    // AFTER THE CHANGE IS APPLIED REMOVE ELEMENT FROM STORE
    setTimeout(() => {
      this.deleteElement();
    }, 1500);
  }

  deleteElement() {
    this.dialog = false;
    this.$store.commit(`scenarios/${ScenariosAction.DeleteScenarioElement}Handler`, this.scenarioElement.uuid);
  }

  updateChart() {
    setTimeout(() => {
      if (this.graph && this.graph.changes.length && this.graph !== undefined) {
        this.graph.changes = this.optimizedGraph.changes;
      }
    }, 800);
  }
}
