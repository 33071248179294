import { render, staticRenderFns } from "./OptimizationCardTabbedList.vue?vue&type=template&id=881e3ae2&scoped=true&"
import script from "./OptimizationCardTabbedList.vue?vue&type=script&lang=ts&"
export * from "./OptimizationCardTabbedList.vue?vue&type=script&lang=ts&"
import style0 from "./OptimizationCardTabbedList.vue?vue&type=style&index=0&id=881e3ae2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881e3ae2",
  null
  
)

export default component.exports